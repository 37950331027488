<template>
    <div class="text-center" v-if="!is_data_ready">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="ftb-md-over"  v-else-if="match">
        <div class="ftb_match_info_section" >
            <h2>{{$t('football.match-detail.Match Information')}}</h2>
            <div class="ftb_match_info_table">
                <ul>
                    <li>
                        <label>{{$t('football.match-detail.Competition')}}</label>
                        <span>{{match.tournament.name}}</span>
                    </li>
                    <li>
                        <label>{{$t('football.match-detail.Round')}}</label>
                        <span>{{match.round}}  </span>
                    </li>
                    <li v-if="match.venue">
                        <label>{{$t('football.match-detail.Venue')}}</label>
                        <span>{{match.venue.name}}</span>
                    </li>
                    <li v-if="match.officials.length > 0">
                        <label>{{$t('football.match-detail.Referee')}}</label>
                        <span>{{match.officials[0].name}} </span>
                    </li>
                    <li>
                        <label>{{$t('football.match-detail.Date / Time')}}</label>
                        <span>{{match.date | formatDate('Do MMM yyyy, HH:mm')}}</span>
                    </li>
                </ul>
            </div>
        </div>
        <!-- hide channel info untill we get sabc channels -->
        <!-- <div class="ftb_match_info_section" v-if="match.channels.length > 0">
            <h2 style="display: flex;">TV </h2>
            <div class="ftb_match_info_table">
                <ul>
                    <li>
                        <label>{{$t('football.match-detail.Channel Name')}}</label>
                        <span><b>{{$t('football.match-detail.On Air Time')}}</b></span>
                    </li>
                    <li v-for="channel in match.channels" :key="channel.id">
                        <label>{{channel.channel_name}}</label>
                        <span>{{channel.on_air_date | formatDate4}} {{channel.on_air_date | formatDate5}}</span>
                    </li>
                </ul>
            </div>
        </div> -->
    </div>
</template>

<script>

    export default {
        name: 'MatchDetailOverview',
        data() {
            return {
                match: null,
            }
        },
        serverPrefetch() {
            return this.getDetail();
        },
        mounted() {
            this.getDetail();
        },
        metaInfo() {
            let ss = this.$store.state.siteSetting;
            if(this.$store.state.fbMatchDetailResponse && this.$store.state.fbMatchDetailResponse.data){
              var replacements = {
                  '%HOME_TEAM%': this.$filterTeam(this.$store.state.fbMatchDetailResponse.data.competitors, true).name,
                  '%AWAY_TEAM%': this.$filterTeam(this.$store.state.fbMatchDetailResponse.data.competitors, false).name,
                  '%DATE%' : this.$route.params.date,
              };

              let title = this.$getSafe(() => this.$replacePlaceHolders(ss.football_match_overview_meta_title,replacements));
              let description = this.$getSafe(() => this.$replacePlaceHolders(ss.football_match_overview_meta_description,replacements));
              let keywords = this.$getSafe(() => ss.football_match_overview_meta_keywords);

              return {
                  title: title,
                  description: description,
                  meta: this.$getMetaTags(title, description, keywords),
                  link:[
                      {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}],
              };
            }
        },
        methods: {
            getDetail() {
                this.is_data_ready = false;
                return this.$axios
                    .get(this.$MATCH_FOOTBALL_DETAIL_API.replace(':match', this.$route.params.matchId))
                    .then(response => {
                        this.match = response.data.match;
                        this.is_data_ready = true;
                    })
                    .catch(error => console.log(error))
            },
        }
    }

</script>
<style scoped>
.ftb-md-over{background: #ffffff;}
</style>
